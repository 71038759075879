import React from 'react';
// import dynamic from 'next/dynamic';
import GrassdoorCategoriesSkeleton from './grassdoor/CategoriesSkeleton'
// const GrassdoorCategoriesSkeleton = dynamic(() => import('./grassdoor/CategoriesSkeleton'));

const CategorySkeleton = props => {
  return <GrassdoorCategoriesSkeleton {...props} />;
};

// Wrap the component with React.memo to prevent unnecessary re-renders
export default React.memo(CategorySkeleton);
