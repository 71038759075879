import React, { useEffect, useRef, useState } from "react";

export default function SeeMore({ text, className = "" }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    const checkTruncation = () => {
      if (textRef.current) {
        const lineHeight = parseFloat(getComputedStyle(textRef.current).lineHeight);
        const maxHeight = lineHeight * 2; // Two lines' height
        if (textRef.current.scrollHeight > maxHeight) {
          setIsTruncated(true);
        } else {
          setIsTruncated(false);
        }
      }
    };

    checkTruncation();
    window.addEventListener("resize", checkTruncation);

    return () => {
      window.removeEventListener("resize", checkTruncation);
    };
  }, [text]);

  if (!text) return null;

  return (
    <div className={`relative text-[16px] leading-[20px] text-[#616161] mt-[10px] ${className}`}>
      <p
        ref={textRef}
        className={`transition-all overflow-hidden relative ${isTruncated && !isExpanded ? "line-clamp-2" : "line-clamp-none"
          }`}
      >
        {text}
        {isTruncated && (
          <span className="">
            {!isExpanded ? (
              <span className="absolute bottom-0 right-0 bg-[var(--body-background-color)] text-[#283cd5] cursor-pointer">
                <button onClick={() => setIsExpanded(true)} className="text-[#283cd5]">
                  <span className="text-[#616161]">...</span>See more
                </button>
              </span>
            ) : (
              <span
                className="ml-1 text-[#283cd5] cursor-pointer"
                onClick={() => setIsExpanded(false)}
              >
                <u>See less</u>
              </span>
            )}
          </span>
        )}
      </p>
    </div>
  );
}
